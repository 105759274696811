import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/how-to-use-grunt",
  "date": "2014-03-29",
  "title": "HOW TO USE GRUNT",
  "author": "admin",
  "tags": ["development", "javascript", "grunt"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article, we’ll look at how to use Grunt in your project to automate some of your repetitive tasks. We’ll look at what Grunt is, how to setup Grunt and then create a simple project with Grunt doing the heavy lifting of the some of the tasks."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We’ll then look at how to build a simple input validator, using `}<a parentName="p" {...{
        "href": "http://sass-lang.com/"
      }}>{`Sass`}</a>{` as a preprocessor,
how to use grunt-css and CssMin to combine and minify our CSS, how to use `}<a parentName="p" {...{
        "href": "http://htmlhint.com/"
      }}>{`HTMLHint`}</a>{`to make
sure our HTML is written correctly, and how to build our compressed assets on the fly. `}</p>
    <p>{`Lastly, we’ll look at using `}<a parentName="p" {...{
        "href": "https://github.com/mishoo/UglifyJS"
      }}>{`UglifyJS`}</a>{` to reduce the size of our JavaScript and ensure that our
website uses as little bandwidth as possible.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-29-how-to-use-grunt/grunt-javascript-task-runner-38671482.jpg",
        "alt": "Grunt task runner"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "http://gruntjs.com/"
      }}>{`Grunt.js`}</a>{` is a JavaScript task runner that helps you perform repetitive tasks such as minification, compilation, unit testing or linting.`}</p>
    <h2>{`Getting Started With Grunt`}</h2>
    <p>{`Most developers would agree that the speed and pace of JavaScript development over the last few years has been pretty astounding. Whether with frameworks such as `}<a parentName="p" {...{
        "href": "http://backbonejs.org/"
      }}>{`Backbone.js`}</a>{` and `}<a parentName="p" {...{
        "href": "http://emberjs.com/"
      }}>{`Ember.js`}</a>{` or with communities such as `}<a parentName="p" {...{
        "href": "http://jsbin.com/welcome/1/edit"
      }}>{`JS Bin`}</a>{`, the development of this language is changing not only the way we experience websites as users but also the way we build them.
When you are working with JavaScript, you will likely need to execute multiple tasks regularly. While this is pretty much a given in most projects, it’s a time-consuming and repetitive way to work. Being in such an active community, you would assume that tools are available to automate and speed up this process. This is where Grunt comes in.`}</p>
    <h2>{`What Is Grunt?`}</h2>
    <p>{`Built on top of Node.js, Grunt is a task-based command-line tool that speeds up workflows by reducing the effort required to prepare assets for production. `}</p>
    <p>{`It does this by wrapping up jobs into tasks that are compiled automatically as you go along.
Basically, you can use Grunt on most tasks that you consider to be grunt work and would normally have to manually configure and run yourself.
While earlier versions came bundled with plugins like JSHint and Uglyify, the most recent release (version 0.4) relies on plugins for everything.
What kind of tasks? Well, the list is exhaustive. Suffice it to say, Grunt can handle most things you throw at it, from `}<a parentName="p" {...{
        "href": "https://npmjs.org/package/grunt-minified"
      }}>{`minifying`}</a>{` to `}<a parentName="p" {...{
        "href": "https://github.com/gruntjs/grunt-contrib-concat"
      }}>{`concatenating`}</a>{` JavaScript. `}</p>
    <p>{`It can also be used for a range of tasks unrelated to JavaScript, such as compiling CSS from LESS and Sass. We’ve even used it with `}<a parentName="p" {...{
        "href": "http://thingm.com/products/blink-1.html"
      }}>{`blink(1)`}</a>{` to notify us when a build fails.`}</p>
    <h2>{`Why Use Grunt?`}</h2>
    <p>{`One of the best things about Grunt is the consistency it brings to teams.
If you work collaboratively, you’ll know how frustrating inconsistency in the code can be.
Grunt enables teams to work with a unified set of commands, thus ensuring that everyone on the team is writing code to the same standard. After all, nothing is more frustrating than a build that fails because of little inconsistencies in how a team of developers writes code.
Grunt also has an incredibly active community of developers, with new plugins being released regularly. The barrier to entry is relatively low because a vast range of tools and automated tasks are already available to use.`}</p>
    <h2>{`Setting Up`}</h2>
    <p>{`The first thing to do in order to use Grunt is to set up Node.js. (If you know nothing about Node.js, don’t worry — it merely needs to be installed in order for Grunt to be able to run.)
Once Node.js is installed, run this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install -g grunt-cli
`}</code></pre>
    <p>{`To make sure Grunt has been properly installed, you can run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`grunt --version
`}</code></pre>
    <p>{`The next step is to create a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` and a `}<inlineCode parentName="p">{`gruntfile.js`}</inlineCode>{` file in the root directory of your project.`}</p>
    <h2>{`Creating the package.json File`}</h2>
    <p>{`The JSON file enables us to track and install all of our development dependencies. `}</p>
    <p>{`Then, anyone who works on the project will have the most current dependencies, which ultimately helps to keep the development environments in sync.`}</p>
    <p>{`Create a file in the root of your project that contains the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "name": "SampleGrunt",
    "version": "0.1.0",
    "author": "Sean Amarasinghe",
    "private": true,

    "devDependencies": {
        "grunt": "~0.4.0"
    } 
}
`}</code></pre>
    <p>{`Once you have done this, run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install
`}</code></pre>
    <p>{`This tells npm which dependencies to install and places them in a `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` folder.`}</p>
    <h2>{`Creating the gruntfile.js File`}</h2>
    <p><inlineCode parentName="p">{`Gruntfile.js`}</inlineCode>{` is essentially made up of a wrapper function that takes `}<inlineCode parentName="p">{`grunt`}</inlineCode>{` as an argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`module.exports = function(grunt){
    grunt.initConfig({
        pkg: grunt.file.readJSON('package.json')
    });

    grunt.registerTask('default', []);
};
`}</code></pre>
    <p>{`You are now set up to run Grunt from the command line at the root of your project. But if you do so at this stage, you will get the following warning:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`grunt
> Task "default" not found. Use --force to continue. 
`}</code></pre>
    <p>{`We’d get this because we haven’t specified any tasks or dependencies yet other than Grunt. So, let’s do that. But first, let’s look at how to extend the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file.`}</p>
    <h2>{`Extending the `}<inlineCode parentName="h2">{`package.json`}</inlineCode>{` File`}</h2>
    <p>{`The best thing about working with Node.js is that it can find packages and install them in one go, simply based on the contents of the package file. To install all of the new dependencies, just add this to the file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "name": "SampleGrunt",
    "version": "0.1.0",
    "author": "Sean Amarasinghe",
    "private": true,

    "devDependencies": {
        "grunt":                       "~0.4.0",
        "grunt-contrib-cssmin":         "*",
        "grunt-contrib-sass":           "*",
        "grunt-contrib-uglify":         "*",
        "grunt-contrib-watch":          "*",
        "grunt-cssc":                   "*",
        "grunt-htmlhint":               "*",
        "matchdep":                     "*"
    }
}
`}</code></pre>
    <p>{`And to complete the process? You guessed it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install
`}</code></pre>
    <h2>{`Loading npm Tasks In Grunt`}</h2>
    <p>{`Now that the packages have been installed, they have to be loaded in Grunt before we can do anything with them. We can load all of the tasks automatically with a single line of code, using the `}<inlineCode parentName="p">{`matchdep`}</inlineCode>{`dependency. This is a boon for development because now the dependency list will be included only in the package file.`}</p>
    <p>{`At the top of `}<inlineCode parentName="p">{`gruntfile.js`}</inlineCode>{`, above `}<inlineCode parentName="p">{`grunt.initConfig`}</inlineCode>{`, paste this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require("matchdep").filterDev("grunt-*").forEach(grunt.loadNpmTasks);
`}</code></pre>
    <p>{`Without `}<inlineCode parentName="p">{`matchdep`}</inlineCode>{`, we would have to write `}<inlineCode parentName="p">{`grunt.loadNpmTasks("grunt-task-name");`}</inlineCode>{` for each dependency, which would quickly add up as we find and install other plugins.`}</p>
    <p>{`Because the plugins are loaded into Grunt, we may start specifying options. `}</p>
    <p>{`First off is the HTML file (`}<inlineCode parentName="p">{`index.html`}</inlineCode>{`), which contains the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
<html>
    <head>
        <title>Grunt Sample</title>
        <link rel="stylesheet"  href="build/css/master.css">
    </head>
    <body>

        <label for="firstname">Enter your first name</label>
        <input id="firstname" name="firstname" type="text">
        <p id="namevalidation"></p>

        <script type="text/javascript" src="build/js/base.min.js"></script>

    </body>
</html>
`}</code></pre>
    <h2>{`Validating With HTMLHint`}</h2>
    <p>{`Add this configuration to `}<inlineCode parentName="p">{`grunt.initConfig`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`hint: {
    build: {
        options: {
            'tag-pair': true,
            'tagname-lowercase': true,
            'attr-lowercase': true,
            'attr-value-double-quotes': true,
            'doctype-first': true,
            'spec-char-escape': true,
            'id-unique': true,
            'head-script-disabled': true,
            'style-disabled': true
        },
        src: ['index.html']
    }
}
`}</code></pre>
    <p>{`A plugin is typically configured like this: the plugin’s name (without the`}<inlineCode parentName="p">{`grunt-contrib-/grunt-`}</inlineCode>{` prefix), then one or more targets of your choosing (which can be used to create custom options for the plugin for different files), an `}<inlineCode parentName="p">{`options`}</inlineCode>{` object, and the files it affects. Now, when we run `}<inlineCode parentName="p">{`grunt htmlhint`}</inlineCode>{` from the terminal, it will check through the source file and make sure that our HTML has no errors! However, manually typing this command several times an hour would get tedious pretty quickly.`}</p>
    <h2>{`Automate Tasks That Run Every Time A File Is Saved`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`watch`}</inlineCode>{` task can run a unique set of tasks according to the file being saved, using targets. Add this configuration to `}<inlineCode parentName="p">{`grunt.initConfig`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`watch: {
    html: {
        files: ['index.html'],
        tasks: ['htmlhint']
    }
}
`}</code></pre>
    <p>{`Then, run `}<inlineCode parentName="p">{`grunt watch`}</inlineCode>{` in the terminal. Now, try adding a comment to `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`. You’ll notice that when the file is saved, validation is automatic! This is a boon for development because it means that `}<inlineCode parentName="p">{`watch`}</inlineCode>{`will silently validate as you write code, and it will fail if the code hasn’t passed the relevant tests (and it will tell you what the problem is).`}</p>
    <p>{`Note that `}<inlineCode parentName="p">{`grunt watch`}</inlineCode>{` will keep running until the terminal is closed or until it is stopped (`}<inlineCode parentName="p">{`Control + C`}</inlineCode>{` on a Mac).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      